<template>
  <base-view
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
    <template v-slot:actions:extras>
      <span style="color:green"><strong>Obs.: </strong>A visualização dos boletos só estará disponível, após o registro na instituição financeira.</span>
    </template>
    
    <comprovante-pagamento-dialog :item="item" v-model="envioComprovanteDialogOpened"
                             @onSave="() => $refs.dataTable.refresh()"/>
    <div>
      <v-progress-linear
        indeterminate
        color="cyan"
        v-show="mostraLoadBoleto"
      ></v-progress-linear>
      <minhas-faturas-data-table ref="dataTable"
                             @click:viewBoleto="verBoleto"
                             @click:viewRecibo="verRecibo"
                             @click:sendComprovante="enviarComprovante"
                           :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import MinhasFaturasDataTable from "../../components/shared/datatables/MinhasFaturasDataTable";
import BaseView from "../BaseView";
import {findAllClienteLogadoMaisSeusDependentes as findAllCliente} from '../../api/clientes';
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";
import {ApiError} from "../../api/core";
import * as moment from 'moment';
import ComprovantePagamentoDialog from "./components/ComprovantePagamentoDialog";
import {downloadRecibo, downloadBoleto} from "../../api/relatorios";

export default {
  name: "index",
  components: {BaseView, MinhasFaturasDataTable, ComprovantePagamentoDialog},
  data() {
    return {
      item: null,
      envioComprovanteDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          group: 'Período',
          columns: [
            {
              name: 'De',
              column: 'dtInicio',
              type: 'date',
              defaultValue: moment().startOf('month').format('YYYY-MM-DD'),
            },
            {
              name: 'Até',
              column: 'dtFim',
              type: 'date',
              defaultValue: moment().endOf('month').format('YYYY-MM-DD'),
            },
          ]
        },
        {
          name: 'Nosso Número',
          column: 'id',
          type: 'number'
        },
        {
          name: 'Cliente',
          column: 'idCliente',
          type: 'autocomplete',
          optionsFactory: (search) => findAllCliente(1, {
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.nome
                }))
              })

        },
        {
          name: 'Status',
          column: 'status',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.STS_PAGAMENTO
          })
        },
      ],
      mostraLoadBoleto: false,
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    async verBoleto(item) {
      this.mostraLoadBoleto = true;

      if (item.statusDaFatura === 'R') {
        item.formatoSaida = "pdf";
        await downloadBoleto(item);
      } else if (item.statusDaFatura === 'I') {
        window.open(item.urlBoletoIntegracao, '_blank');
      }      
      
      this.mostraLoadBoleto = false;
    },    
    async verRecibo(item) {
      item.formatoSaida = "pdf";
      await downloadRecibo(item);
    },
    enviarComprovante(item){
      this.item = JSON.parse(JSON.stringify(item));
      this.envioComprovanteDialogOpened = true;      
    },
  },
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>